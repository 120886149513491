import { gql } from "@apollo/client";

export const saveTaskTemplate = gql`
  mutation savePostGrid(
    $id: String
    $title: String!
    $instructions: String!
    $variables: [PostGridTemplateVariablesInput]!
  ) {
    savePostGrid(
      id: $id
      title: $title
      instructions: $instructions
      variables: $variables
    )
  }
`;

export const deleteTemplate = gql`
  mutation deletePostGrid($template_id: String!) {
    deletePostGrid(template_id: $template_id)
  }
`;
