import { gql } from "@apollo/client";

export const getPostGridTemplates = gql`
  query {
    getPostGridTemplates {
      id
      title
      type
      instructions
      variables {
        variable
        mapping
      }
      createdAt
      updatedAt
    }
  }
`;

// "id": "template_c6HSqnsD1h2zoeHJ6Z9EEA",
// "object": "template",
// "live": false,
// "deleted": false,
// "description": "Test",
// "html": "<b>Hello</b> updated world!",
// "createdAt": "2020-11-12T23:15:46.750Z",
// "updatedAt": "2020-11-12T23:16:36.142Z"
