import { Text, Button } from "@centrate-io/barn";
import { CustomTag, TaskBadge } from "_styleguide";
import { IconUser } from "@tabler/icons-react";
import { stripHTML } from "_assets/js/helpers";
import moment from "moment";

export const getColumns = (readOnly, showNewTask, editTemplate) => {
  return [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 100,
      render: (type) => (
        <div className="task-badge-type">
          <TaskBadge full="true" type={type} />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "title",
      key: "title",
      render: (title) => (
        <Text>
          <b>{title}</b>
        </Text>
      ),
    },
    {
      title: "Preview",
      dataIndex: "instructions",
      key: "instructions",
      render: (instructions) => {
        const trimmed = stripHTML(
          instructions?.replaceAll(/\n/g, "").replaceAll("  ", "") || "",
        )?.trim();
        if (trimmed?.length > 43) {
          const stripped = trimmed.slice(0, 40);
          return <Text>{stripped}...</Text>;
        } else if (trimmed?.length > 0) {
          return <Text>{trimmed}</Text>;
        } else {
          return (
            <Text>
              <i>n/a</i>
            </Text>
          );
        }
      },
    },
    // {
    //   title: "Variables",
    //   dataIndex: "variables",
    //   key: "variables",
    //   render: (variables) => <Text>{variables?.length || 0}</Text>,
    // },
    {
      title: "Last Updated",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (updatedAt) => (
        <Text>{moment(updatedAt).format("MMM D, YYYY")}</Text>
      ),
    },
    {
      title: <div className="column-name">&nbsp;</div>,
      dataIndex: "manage",
      key: "manage",
      width: 100,
      align: "center",
      fixed: "right",
      render: (address, data) => (
        <div className="table-actions">
          <Button
            type="blanco"
            size="small"
            className="boxed"
            onClick={() => editTemplate(data)}
          >
            Manage Template
          </Button>
        </div>
      ),
    },
  ];
};
