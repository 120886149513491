import { useState } from "react";
import { useApolloClient } from "@apollo/client";
import { Button, Modal, message } from "@centrate-io/barn";
import { IconCards, IconTrash } from "@tabler/icons-react";
import TemplateContent from "./_components/TemplateContent/TemplateContent";
import TemplateMapping from "./_components/TemplateMapping/TemplateMapping";
import TemplatePreview from "./_components/TemplatePreview/TemplatePreview";
import { validateTemplate } from "App/Admin/_helpers/validateForm";
import { extractVariables, capitalize } from "_assets/js/helpers";
import {
  saveTaskTemplate,
  deleteTemplate,
} from "App/Admin/_graphql/mutations/postgrid";
import { getErrors } from "_helpers/api";
import "./PostGridModal.scss";

const defaultForm = {
  title: undefined,
  type: "mail",
  instructions: undefined,
  subject: undefined,
  variables: [],
  is_html: true,
};

function PostGridModal(props) {
  const client = useApolloClient();
  const [visible, setVisible] = useState(true);
  const [saving, setSaving] = useState(false);
  const [tab, setTab] = useState("content"); // content > mapping > preview
  const [form, setForm] = useState(
    props.template?.id ? props.template : { ...defaultForm, type: props.type },
  );

  const jsonForm = props.template?.id
    ? JSON.stringify(props.template)
    : JSON.stringify(defaultForm);
  const isEditing = props.template?.id ? true : false;
  const hasChanged = JSON.stringify(form) !== jsonForm;

  // Update form by field and value
  const updateForm = (field, value) => {
    const editedform = { ...form };
    editedform[field] = value;
    setForm(editedform);
  };
  const validation = validateTemplate(form);

  // Create Task Template
  const createTemplate = async () => {
    setSaving(true);
    try {
      await client.mutate({
        variables: { ...form },
        mutation: saveTaskTemplate,
      });
      message.success(`Task template ${isEditing ? "saved" : "created"}`);
      if (props.reload) props.reload();
      setVisible(false);
    } catch (err) {
      message.error(getErrors(err) || "Error saving template, try again");
      setSaving(false);
    }
  };
  const removeTemplate = async (template_id) => {
    Modal.confirm({
      title: "Are you sure you want remove this template?",
      okText: "Remove Template",
      content:
        "This action cannot be reversed and will remove the template and it can no longer be used to create tasks from.  It will not affect any current tasks created from it.",
      onOk: async () => {
        try {
          await client.mutate({
            variables: { template_id: props.template?.id },
            mutation: deleteTemplate,
          });
          message.success(`Template has been removed`);
          if (props.reload) props.reload();
          setVisible(false);
        } catch (err) {
          message.error(getErrors(err) || "Error removing template, try again");
        }
      },
    });
  };

  const formProps = {
    form,
    updateForm,
    validation,
    saving,
  };

  const goToVariables = () => {
    const variables = extractVariables(form?.instructions);
    const variableMapping = variables.map((v) => {
      const findExisting = form.variables.find((o) => o.variable === v);
      if (findExisting) return findExisting;
      return {
        variable: v,
        mapping: undefined,
      };
    });
    updateForm("variables", variableMapping);
    setTab(variableMapping.length > 0 ? "mapping" : "preview");
  };

  const goBack = () => {
    if (tab === "mapping") setTab("content");
    if (tab === "preview" && form.variables.length > 0) {
      setTab("mapping");
    } else {
      setTab("content");
    }
  };

  const titles = {
    content: `${isEditing ? "Edit" : "Create"} Template Content`,
    mapping: `Variable Mapping`,
    preview: `${capitalize(form.type)} Preview`,
  };

  const contentValid = () => {
    const emailValid =
      form.type === "email" ? (validation.subject.valid ? true : false) : true;
    if (
      validation.instructions.valid &&
      emailValid &&
      validation.type.valid &&
      validation.title.valid
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Modal
      wrapClassName="create-template-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={false}
      width={800}
    >
      <div className="pp-modal-header">
        <div>
          <IconCards />
        </div>
        <h3>{titles[tab]}</h3>
      </div>

      <Modal.Body>
        {tab === "content" ? <TemplateContent {...formProps} /> : null}
        {tab === "mapping" ? <TemplateMapping {...formProps} /> : null}
        {tab === "preview" ? <TemplatePreview {...formProps} /> : null}
      </Modal.Body>

      <Modal.Actions>
        <div>
          {tab === "mapping" || tab === "preview" ? (
            <Button disabled={saving} type="blanco" onClick={goBack}>
              Previous
            </Button>
          ) : (
            <Button
              disabled={saving}
              type="secondary"
              onClick={() => setVisible(false)}
            >
              Cancel
            </Button>
          )}
        </div>
        <div>
          {isEditing && tab === "content" ? (
            <Button disabled={saving} onClick={removeTemplate} type="nope">
              <IconTrash />
            </Button>
          ) : null}
          {tab === "content" ? (
            <Button
              type="primary"
              disabled={!contentValid()}
              onClick={goToVariables}
            >
              Next Step
            </Button>
          ) : null}
          {tab === "preview" ? (
            <Button
              type="primary"
              disabled={!validation.allValid || !hasChanged}
              onClick={createTemplate}
              loading={saving}
            >
              {isEditing ? "Save" : "Create"} Template
            </Button>
          ) : null}
          {tab === "mapping" ? (
            <Button
              type="primary"
              disabled={tab === "mapping" && !validation?.variables?.valid}
              onClick={() => setTab("preview")}
            >
              Next Step
            </Button>
          ) : null}
        </div>
      </Modal.Actions>
    </Modal>
  );
}

export default PostGridModal;
